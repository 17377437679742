import React, { useCallback, useMemo } from "react";
import { CellProps } from "./Cell";
import { formatMoneyCurrency, formatPercent } from "../../../../utils";
import { Typography } from "@commonsku/styles";
import { BASE_COLUMN_TYPES, ColumnDataMap, REPORT_TOOLTIP_TARGET_ID } from "../config/types";
import { useTooltipAttributes } from "../../../../hooks";

type TColumn = typeof BASE_COLUMN_TYPES.Margin;
const MarginCell = ({
  column,
  row,
  className,
  onClick,
  style = {},
  textStyle = {},
}: CellProps<TColumn>) => {
  const {
    accessorKey,
    dataTransform,
    moneyOptions: { decimalPlaces: amountDecimalPlaces = 0 },
    percentageOptions: { decimalPlaces: percentDecimalPlaces = 0 }
  } = column;

  const {
    percent,
    amount,
    currency_id,
    tooltipText,
    tooltipOptions
  } = useMemo<ColumnDataMap<TColumn>>(() => {
    if (!dataTransform) {
      throw new Error("MarginCell requires dataTransform");
    }

    const {
      amount,
      percent,
      currency_id,
       tooltipText,
       tooltipOptions
    } = dataTransform(row.original[accessorKey]);
    return { amount, percent, currency_id, tooltipText, tooltipOptions };
  }, [accessorKey, dataTransform, row.original]);

  const percentageText = useCallback(
    (decimal = percentDecimalPlaces) => {
      return formatPercent(percent, decimal);
    },
    [percent, percentDecimalPlaces]
  );

  const moneyText = useCallback(
    (decimal = amountDecimalPlaces) =>
       amount === null ? '—' : formatMoneyCurrency(amount, currency_id, decimal),
    [amount, currency_id, amountDecimalPlaces],
  );

  const tooltipAttributes = useTooltipAttributes(
    tooltipText ?? `${percentageText(0)} (${moneyText(2)})`,
    REPORT_TOOLTIP_TARGET_ID,
    tooltipOptions
  );

  return (
    <div className={className} onClick={onClick}>
      <div style={style} {...tooltipAttributes}>
        <Typography.Span
          style={{
            display: "block",
            textAlign: "right",
            alignContent: "center",
            color: style.color,
            ...textStyle,
          }}
        >
          {percentageText()}
        </Typography.Span>
        <Typography.Span
          style={{
            display: "block",
            textAlign: "right",
            alignContent: "center",
            color: style.color,
            ...textStyle,
          }}
        >
          {moneyText()}
        </Typography.Span>
      </div>
    </div>
  );
};

export default MarginCell;
